/*
 * P&F DRE web application
 *
 * Page for viewing information about a specific device.
 */

import React from 'react';
import { useParams } from 'react-router-dom';

import DeviceView, { TDeviceInfo } from '../../../components/DeviceView';
import PageError from '../../../pages/error';
import { Page, PageHeading, PageContent } from '../../../components/Page';

import './styles.css';

const PageDeviceView: React.FC = () => {
	const params = useParams();
	const [deviceInfo, setDeviceInfo] = React.useState<TDeviceInfo | null>(null);

	const id = params.id ? parseInt(params.id, 10) : null;

	// Update the page title based on the device loaded, once it has loaded.
	const title = React.useMemo<React.ReactNode>(() => (
		<>
			<span className="code">{deviceInfo?.code ?? '-'}</span>
			<span className="name">{deviceInfo?.name ?? 'Device Information'}</span>
		</>
	), [
		deviceInfo,
	]);
	const docTitle = React.useMemo<string>(() => (
		(deviceInfo?.code ?? '-')
		+ ' '
		+ (deviceInfo?.name ?? 'Device Information')
	), [
		deviceInfo,
	]);


	if (!id) {
		return (
			<PageError title="Invalid device ID">
				<p>
					The device ID specified in the URL is of the wrong data type.
				</p>
			</PageError>
		);
	}

	return (
		<Page classSuffix="DeviceView">
			<PageHeading title={title} docTitle={docTitle} />
			<PageContent>
				<DeviceView id={id} fnSetDeviceInfo={setDeviceInfo} />
			</PageContent>
		</Page>
	);
}

export default PageDeviceView;
