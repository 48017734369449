/*
 * P&F DRE web application
 *
 * Add a new job/task to the database.
 */

import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import {
	useJobNewMutation,
} from '../../generated/graphql';

import { APIError } from '../ErrorMessage';
import JobNew from './JobNew';

export interface TJobInfo {
	jobType: string;
	priority?: number;
	parameters?: string;
	runAfter?: string;
};

const JobNewContainer: React.FC = () => {
	const navigate = useNavigate();
	const [jobNew, { error }] = useJobNewMutation();

	const addJob = React.useCallback(async (jobInfo: TJobInfo) => {
		const idJob = await jobNew({ variables: jobInfo });
		navigate('../?highlight=' + idJob?.data?.jobNew);
	}, [
		jobNew,
		navigate,
	]);

	return (
		<>
			{error && (
				<APIError apolloError={error} />
			)}
			<JobNew fnAddJob={addJob} />
		</>
	);
};

export default JobNewContainer;
