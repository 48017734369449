/*
 * P&F DRE web application
 *
 * Display single database connection and a button to disconnect it.
 */

import * as React from 'react';
import { ApolloError } from '@apollo/client';
import Moment from 'moment';
import { JobListQuery } from '../../generated/graphql';
import Button from '../Button';
import './styles.css';

export type TJob = JobListQuery['jobs'][0];
export type TFnRetry = () => void;

interface Props {
	job: TJob;
	fnRetry: TFnRetry;
	isLoading?: boolean;
	error?: ApolloError;
	highlight?: boolean;
}

const JobListEntry: React.FC<Props> = ({
	job: j,
	fnRetry,
	isLoading,
	error,
	highlight,
}) => {

	// Convert the creation time to "X minutes ago".  We don't use
	// React.useMemo() for this because we want it to update on every render,
	// so the "X minutes ago" is always up to date.
	const strCreatedAt = j.createdAt ? Moment(j.createdAt).fromNow() : '-';

	// Convert the anticipation time to "X minutes ago".  Not using useMemo() for
	// the same reason as above.
	const strAnticipatedBy = j.anticipatedBy ? Moment(j.anticipatedBy).fromNow() : null;

	// Convert the anticipation time to "X minutes ago".  Not using useMemo() for
	// the same reason as above.
	const strActiveAt = j.activeAt ? Moment(j.activeAt).fromNow() : null;

	// Convert the hold time to "X minutes".
	let strRunAfter = null;
	if (j.runAfter) {
		const t = Moment(j.runAfter);
		if (t.isAfter(Moment())) {
			strRunAfter = t.fromNow(true);
		} // else time is in the past, not holding job.
	}

	const jobType = j.jobType;
	const jobParameters = j.parameters;

	const params = React.useMemo<any>(() => JSON.parse(jobParameters), [
		jobParameters,
	]);

	let friendlyTitle = React.useMemo<string>(() => {
		switch (jobType ?? '') {
			case 'updateIntervalMetrics': {
				let target = '';
				if (params['deviceCodes']) {
					target += params['deviceCodes'].join(', ');
				}
				if (params['idDevices']) {
					if (target.length > 0) target += ', ';
					target += 'D' + params['idDevices'].join(', D');
				}
				if (target.length > 0) {
					target = ' for [' + target + ']';
				}
				return 'Update interval metrics (' + params['startTime'] + ' until ' + params['endTime'] + ')' + target;
			}

			case 'calculateAssetEnergies':
				return 'Calculate energy totals for all assets';

			case 'sumMetricsDaily': {
				let target = '';
				if (params['deviceCodes']) {
					target += params['deviceCodes'].join(', ');
				}
				if (params['idDevices']) {
					if (target.length > 0) target += ', ';
					target += 'D' + params['idDevices'].join(', D');
				}
				if (target.length > 0) {
					target = ' for [' + target + ']';
				}
				return 'Calculate daily totals for all devices (' + params['dayStart'] + ' until ' + params['dayEnd'] + ')' + target;
			}

			case 'recalculateVirtualMeter': {
				let target = '';
				if (params['deviceCodes']) {
					target += params['deviceCodes'].join(', ');
				}
				if (params['idDevices']) {
					if (target.length > 0) target += ', ';
					target += 'D' + params['idDevices'].join(', D');
				}
				if (target.length > 0) {
					target = ' for [' + target + ']';
				}
				return 'Recalculate virtual meters (' + params['startTime'] + ' until ' + params['endTime'] + ')' + target;
			}

			case 'calculateNem5MinCSV':
				return 'Calculate 5-minute NEM CSV'; // TODO: better explanation

			default:
				return jobType ?? '(blank)';
		}
	}, [
		jobType,
		params,
	]);

	const jobFailed = j.failureCount > 0;
	let icon = <i title="Job ready to run">🏗️</i>;
	let className = '';
	if (jobFailed) {
		icon = <i title="Job failed, manual action required">💥</i>;
		className = 'JobFailed';
	} else if (strRunAfter) {
		icon = <i title="Job held until future time">🕓</i>;
		className = 'JobDelayed';
	} else if (strAnticipatedBy) {
		icon = <i title="Job is running right now">⏩</i>;
		className = 'JobActive';
	}

	if (highlight) {
		className += ' highlight';
	}

	return (
		<li className={`JobListEntry ${className}`}>
			{icon}
			<div className="left">
				<h4 className="jobType" title={'System job code: ' + j.jobType}>
					[J{j.idJob}] {friendlyTitle}
					<span className="percentComplete">
						{Math.round(j.percentComplete * 100)}%
					</span>
				</h4>
				<h5>
					Last status: {j.msgProgress ?? '(no status message set)'}
				</h5>
				<div className="subtitle">
					<div className="createdAt" title="Time of creation">
						Added: {strCreatedAt}
					</div>
					<div className="priority" title="Larger numbers are run first, priority 0 is lowest">
						Priority: {j.priority}
					</div>
					{strActiveAt && (
						<div className="activeAt" title="How long ago the job started running">
							Started: {strActiveAt}
						</div>
					)}
					{strAnticipatedBy && (
						<div className="anticipatedBy" title="How long until the job will be considered failed">
							Timeout: {strAnticipatedBy}
						</div>
					)}
					{strRunAfter && (
						<div className="runAfter" title="Hold job until this time">
							Job held for another: {strRunAfter}
						</div>
					)}
					{j.idJobWaitFor && (
						<div className="waitFor" title="Hold job until this job has completed first">
							Waiting for job: J{j.idJobWaitFor}
						</div>
					)}
				</div>
			</div>

			{(j.failureCount > 0) && (
				<Button
					type="refresh"
					isActive={isLoading}
					onClick={fnRetry}
					title="Set the failure count to zero, so the job will run again shortly."
				>
					Retry
				</Button>
			)}
		</li>
	);
};

export default JobListEntry;
