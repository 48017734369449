/*
 * P&F DRE web application
 *
 * Routing for device pages.
 */

import React from 'react';
import {
	Routes,
	Route,
} from 'react-router-dom';

import InvalidURL from '../error/invalid-url';
import { addProtectedRoute } from '../error/addProtectedRoute';

import PageDeviceSearch from './search';
import PageDeviceView from './view';

const PageAssets: React.FC = () => (
	<Routes>
		{addProtectedRoute('/search', PageDeviceSearch)}
		{addProtectedRoute('/view/:id', PageDeviceView)}
		<Route path="*" element={<InvalidURL />} />
	</Routes>
);

export default PageAssets;
