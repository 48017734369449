/*
 * P&F DRE web application
 *
 * Main application page.
 */

import React from 'react';
import {
	Routes,
	Route,
} from 'react-router-dom';

import './App.css';

import InvalidURL from './pages/error/invalid-url';
import PageDatabase from './pages/db';
import PageDebug from './pages/debug';
import PageAssets from './pages/assets';
import PageDevices from './pages/devices';
import PageHome from './pages/home';
import QuickOpenBar from './components/QuickOpenBar';
import Sidebar from './components/Sidebar';

function App() {
	return (
		<div className="App">
			<QuickOpenBar />
			<Sidebar />
			<div className="AppContent">
				<Routes>
					<Route path="/assets/*" element={<PageAssets />} />
					<Route path="/db/*" element={<PageDatabase />} />
					<Route path="/devices/*" element={<PageDevices />} />
					<Route path="/debug/*" element={<PageDebug />} />
					<Route path="/" element={<PageHome />} />
					<Route path="*" element={<InvalidURL />} />
				</Routes>
			</div>
		</div>
	);
}

export default App;
